import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Ability } from "@casl/ability";
import ReferNavbar from "./ReferNavbar";
import ReferCard from "./ReferCard";
import { shortHospName } from "../../functions/FuncPerjer";
import {
  DateTimeToStrDate,
  StrToDateTime,
  delDays,
} from "../../functions/FuncDateTimes";
import appPropertySet from "../../actions/appPropertySet";
import fetchRefers from "../../actions/Refers/fetchRefers";
import rejectRefer from "../../actions/Refers/rejectRefer";
import acceptRefer from "../../actions/Refers/acceptRefer";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/servicePermission";
import dayjs from "dayjs";
import orm from "../../models/index";

const session = orm.session();

class ReferIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cid: "",
      hospCodeData: [],
      ReferDatas: [],
      hospCode: "all",
      selectedDate: null,
      datePickerChange: false,
      referPoint: "all",
      startDate: new Date(),
      endDate: new Date(),
      Scid: "",
      Sname: "",
      ability: null,
      fetchExpired: false,
      toHospCode: null,
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onHospCodeChange = this.onHospCodeChange.bind(this);
    this.getReferData = this.getReferData.bind(this);
    this.getReferCidData = this.getReferCidData.bind(this);
    this.setHospCodeData = this.setHospCodeData.bind(this);
    this.setReferDatas = this.setReferDatas.bind(this);
    this.onReferPointChange = this.onReferPointChange.bind(this);
    this.setAppData = this.setAppData.bind(this);
    this.onCidChange = this.onCidChange.bind(this);
    this.handleAcceptRefer = this.handleAcceptRefer.bind(this);
    this.handleRejectRefer = this.handleRejectRefer.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.onPreviousDayChange = this.onPreviousDayChange.bind(this);
    this.onNextDayChange = this.onNextDayChange.bind(this);
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    const { match, appData, history } = this.props;

    const { params } = match;

    if (!params.startDate && appData.loginData) {
      this.setState({ selectedDate: new Date() });
      this.setState({ startDate: new Date() });
      this.setState({ endDate: new Date() });
      history.push(
        `/refer-in/${DateTimeToStrDate(new Date())}/${DateTimeToStrDate(
          new Date()
        )}`
      );
      this.getReferData(DateTimeToStrDate(new Date()), this.state.toHospCode);
    }

    if (
      (params.startDate !== startDate || params.endDate !== endDate) &&
      params.startDate &&
      params.endDate &&
      appData.loginData
    ) {
      this.setState({ selectedDate: StrToDateTime(params.startDate) });
      this.setState({ startDate: StrToDateTime(params.startDate) });
      this.setState({ endDate: StrToDateTime(params.startDate) });
      history.push(`/refer-in/${params.startDate}/${params.endDate}`);
      this.getReferData(params.startDate, this.state.toHospCode);
    }
    setInterval(() => {
      this.setState({ fetchExpired: true });
    }, 300000); // 5 minute
  }

  componentDidUpdate() {
    const { selectedDate, startDate, datePickerChange, ability, fetchExpired } =
      this.state;
    const { match, appData, history } = this.props;

    const { params } = match;

    if (
      selectedDate !== startDate &&
      selectedDate &&
      datePickerChange &&
      appData.loginData
    ) {
      this.setStartDate();

      history.push(
        `/refer-in/${DateTimeToStrDate(selectedDate)}/${DateTimeToStrDate(
          selectedDate
        )}`
      );
      this.getReferData(DateTimeToStrDate(selectedDate), this.state.toHospCode);
    }

    if (fetchExpired) {
      this.getReferData(DateTimeToStrDate(selectedDate), this.state.toHospCode);
      this.setState({ fetchExpired: false });
    }

    if (!datePickerChange && appData.loginData) {
      if (
        params.startDate &&
        params.startDate !== DateTimeToStrDate(selectedDate)
      ) {
        this.setSelectedDate();

        history.push(`/refer-in/${params.startDate}/${params.startDate}`);
        this.getReferData(params.startDate, this.state.toHospCode);
      }
    }

    if (!ability && appData.permissionRules) {
      this.setState({
        ability: new Ability(appData.permissionRules),
      });
    }
  }

  handleAcceptRefer(id, bid, appointmentInfo) {
    const { appData, handleFetchRefersAccept } = this.props;

    if (bid) {
      const dataRequest = {
        ...appointmentInfo,
        idToken: appData.idToken,
        bid,
      };

      const res = handleFetchRefersAccept(dataRequest);

      res.then(
        () => {
          // ccccc
          if (this.state.SearchType === "cid") {
            this.getReferCidData();
          } else {
            this.getReferData(
              DateTimeToStrDate(this.state.selectedDate),
              this.state.toHospCode
            );
          }
        },
        (e) => {
          // TypeError: Throwing
          if (e.status !== 200) {
            alert("การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
          }
        }
      );
    }
  }

  handleRejectRefer(id, bid, reason) {
    const { appData, handleFetchRefersReject } = this.props;

    if (!reason) {
      alert("คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !");
    } else if (bid) {
      const dataRequest = {
        idToken: appData.idToken,
        bid,
        reason,
      };

      const res = handleFetchRefersReject(dataRequest);

      res.then(
        () => {
          // ccccc
          if (this.state.SearchType === "cid") {
            this.getReferCidData();
          } else {
            this.getReferData(
              DateTimeToStrDate(this.state.selectedDate),
              this.state.toHospCode
            );
          }
        },
        (e) => {
          // TypeError: Throwing
          if (e.status !== 200) {
            alert("การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
          }
        }
      );
    }
  }

  onDateChange(date, toHospCode) {
    this.setState({ datePickerChange: true });
    this.setState({ selectedDate: date });
    this.setState({ toHospCode });
  }

  onNextDayChange = () => {
    if (this.state.selectedDate) {
      const next = dayjs(this.state.selectedDate).add(1, "day");
      if (!next.isAfter(dayjs())) {
        this.onDateChange(next.toDate(), this.state.fromHospCode);
      }
    }
  };

  onPreviousDayChange = () => {
    if (this.state.selectedDate) {
      const previous = dayjs(this.state.selectedDate)
        .subtract(1, "day")
        .toDate();
      this.onDateChange(previous, this.state.fromHospCode);
    }
  };

  onCidChange(e) {
    const re = /^[0-9\b]+$/;

    if (re.test(e.target.value)) {
      this.setState({ Scid: e.target.value });
      this.setState({ Sname: "" });
    } else {
      this.setState({ Scid: "" });
      this.setState({ Sname: e.target.value });
    }

    this.setState({ cid: e.target.value });
  }

  handleFilterChange({ referPoint, hospCode }) {
    const filterByHospCode = (referDatas) =>
      hospCode !== "all" ? referDatas.data.toHospCode === hospCode : true;

    const filterByReferPoint = (referDatas) =>
      referPoint !== "all"
        ? referDatas.data.referPoint !== "opd"
          ? referDatas.data.referPoint === referPoint.toUpperCase()
          : referDatas.data.referPoint !== "ER" &&
            referDatas.data.referPoint !== "IPD"
        : true;

    const referData = session.ReferDatas.all()
      .filter((referDatas) => referDatas.data._id !== "")
      .filter(filterByHospCode)
      .filter(filterByReferPoint)
      .orderBy((referDatas) => referDatas.data.referDateTime, "desc")
      .toRefArray();

    this.setState({ ReferDatas: referData });
  }

  onReferPointChange(e) {
    const referPoint = e.target.value;
    this.setState({ referPoint });
    this.handleFilterChange({ referPoint, hospCode: this.state.hospCode });
  }

  onHospCodeChange(e) {
    const hospCode = e.target.value;
    this.setState({ hospCode });
    this.handleFilterChange({ referPoint: this.state.referPoint, hospCode });
  }

  setStartDate() {
    const { selectedDate } = this.state;

    this.setState({ startDate: selectedDate });
    this.setState({ datePickerChange: false });
  }

  setSelectedDate() {
    const { match } = this.props;

    const { params } = match;

    this.setState({ selectedDate: StrToDateTime(params.startDate) });
  }

  setAppData(appPropertyData) {
    const { handleAppPropertySet } = this.props;

    handleAppPropertySet(appPropertyData);
  }

  getReferCidData(e) {
    const { Scid, Sname } = this.state;
    const { appData, appProperty, handleFetchRefers } = this.props;

    // prevent default for search refer only, except for accept and reject
    if (e) {
      e.preventDefault();
    }

    if (Scid !== "" || Sname !== "") {
      let cid = "";
      let name = "";

      if (Scid.trim().length === 13) {
        cid = Scid;
      }

      if (Sname.trim().length > 0 && Sname !== "") {
        name = Sname;
      }

      if (cid <= "" && name <= "") {
        alert("กรุณากรอก CID / ชื่อ สกุล ไม่ถูกต้อง !");
      } else {
        const std = DateTimeToStrDate(delDays(new Date(), 366 * 3));
        const ste = DateTimeToStrDate(new Date());

        const dataRequest = {
          idToken: appData.idToken,
          startDate: std,
          endDate: ste,
          fromHospCode: "",
          toHospCode: appData.loginData.hospCode,
          cid,
          name,
          limit: appProperty.visitLimit,
        };

        const res = handleFetchRefers(dataRequest);

        res.then(
          () => {
            this.setState({ hospCode: "all" });
            this.setState({ referPoint: "all" });

            this.setHospCodeData();
            this.setReferDatas();

            const appPropertyData = {
              visitSelect: "",
              FetchingStatus: false,
            };

            this.setAppData(appPropertyData);
          },
          (err) => {
            // TypeError: Throwing
            if (err.status !== 200) {
              alert("การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
            }
          }
        );
      }
    }
  }

  getReferData(date, toHospCode) {
    const { appData, appProperty, handleFetchRefers } = this.props;

    const dataRequest = {
      idToken: appData.idToken,
      startDate: date,
      endDate: date,
      fromHospCode: "",
      toHospCode: toHospCode || appData.loginData.hospCode,
      limit: appProperty.visitLimit,
    };

    const res = handleFetchRefers(dataRequest);

    res.then(
      () => {
        this.setState({ hospCode: "all" });
        this.setState({ referPoint: "all" });

        this.setHospCodeData();
        this.setReferDatas();

        const appPropertyData = {
          visitSelect: "",
          FetchingStatus: false,
        };

        this.setAppData(appPropertyData);
      },
      (e) => {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert("การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
        }
      }
    );
  }

  setReferDatas() {
    const { appData } = this.props;

    const referDb = appData.emrData.referDatas;

    session.ReferDatas.delete({
      where(record) {
        return record._id !== "";
      },
    });

    referDb.forEach((val) => {
      session.ReferDatas.upsert(val);
    });

    const referData = session.ReferDatas.all()
      .filter((ReferDatas) => ReferDatas.data._id !== "")
      .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
      .toRefArray();

    this.setState({ ReferDatas: referData });
  }

  setHospCodeData() {
    const { appData } = this.props;

    const referDb = appData.emrData.referDatas;

    session.ReferHosp.delete({
      where(record) {
        return record.id !== "";
      },
    });

    referDb.forEach((val) => {
      session.ReferHosp.upsert({
        id: val.data.fromHospCode,
        hospName: val.fromHospName
          ? shortHospName(val.fromHospName)
          : val.data.fromHospCode,
      });
    });

    const hospCodeData2 = session.ReferHosp.all()
      .filter((ReferHosp) => ReferHosp.id !== "")
      .orderBy((ReferHosp) => ReferHosp.hospName, "asc")
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });
  }

  render() {
    const {
      cid,
      hospCode,
      referPoint,
      ReferDatas,
      hospCodeData,
      selectedDate,
      ability,
    } = this.state;

    return (
      <div id="refer-in">
        <ReferNavbar
          title="Refer In"
          cid={cid}
          hospCode={hospCode}
          referPoint={referPoint}
          hospCodeData={hospCodeData}
          selectedDate={selectedDate}
          onReferPointChange={this.onReferPointChange}
          getReferCidData={this.getReferCidData}
          getReferData={(date, toHospCode) =>
            this.getReferData(date, toHospCode)
          }
          onHospCodeChange={this.onHospCodeChange}
          onCidChange={this.onCidChange}
          onDateChange={(date, toHospCode) =>
            this.onDateChange(date, toHospCode)
          }
          onNextDayChange={this.onNextDayChange}
          onPreviousDayChange={this.onPreviousDayChange}
        />

        {ReferDatas.length > 0 && (
          <div
            className="grid gap-4 p-4 bg-gray-100
            md:grid-cols-2
            lg:grid-cols-3"
          >
            {ReferDatas.map((referral, index) => (
              <ReferCard
                key={referral._id}
                actionRefer={ability.can(
                  ServicePermissionEvent.UPDATE,
                  ServicePermissionService.REFERRAL
                )}
                changeRefer={ability.can(
                  ServicePermissionEvent.UPDATE,
                  ServicePermissionService.CHANGE_REFER_STATUS
                )}
                cardIndex={index + 1}
                referral={referral}
                onAcceptRefer={(appointmentInfo) => {
                  this.handleAcceptRefer(index, referral.bid, appointmentInfo);
                }}
                onRejectRefer={(reason) => {
                  this.handleRejectRefer(index, referral.bid, reason);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAppPropertySet: (appPropertyData) =>
      dispatch(appPropertySet(appPropertyData)),
    handleFetchRefers: (dataRequest) => dispatch(fetchRefers(dataRequest)),
    handleFetchRefersAccept: (dataRequest) =>
      dispatch(acceptRefer(dataRequest)),
    handleFetchRefersReject: (dataRequest) =>
      dispatch(rejectRefer(dataRequest)),
  };
}

ReferIn.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }).isRequired,
  appData: PropTypes.shape({
    loginData: PropTypes.shape({
      username: PropTypes.string,
      hospCode: PropTypes.string,
    }),
    idToken: PropTypes.string,
    emrData: PropTypes.shape({
      referDatas: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
  appProperty: PropTypes.shape({
    visitLimit: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleAppPropertySet: PropTypes.func.isRequired,
  handleFetchRefers: PropTypes.func.isRequired,
  handleFetchRefersAccept: PropTypes.func.isRequired,
  handleFetchRefersReject: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferIn);
