import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // => request placed at the top
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import StyledCalendar from "./css/style";
import { Card, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { EventClickArg } from "@fullcalendar/core";
// import FromHospFilter from "./components/FromHospFilter";
// import DoctorFilter from "./components/DoctorFilter";
import AppointmentForm from "./components/AppointmentForm";
import CalendarToolbar from "./components/CalendarToolbar";
import fetchSchedules from "../../api/AppointmentOPD/fetchSchedule";
import { TSchedule } from "../../types/schedule";
import { IDoctor, TClinic } from "../../types/clinic";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { useParams } from "react-router-dom";
import { IReferral } from "../../types/refers";
import fetchReferral from "../../api/fetchRefer";
import AppointmentDrawer from "./components/AppointmentDrawer";
import MyHospFilter from "./components/MyHospFilter";
import updateAppointment from "../../api/AppointmentOPD/updateAppointment";
import Swal from "sweetalert2";
import "./css/AlertStyle.css";
import ReferDetail from "./components/ReferDetail";

type TEvent = {
  id: string;
  start: Date;
  end: Date;
  title: string;
};
function Calendar() {
  const regexVerifyApppointment = /verify-appointment/;
  const regexDoctorAppointment = /doctor-appointment/;

  const { referId } = useParams<{ referId: string }>();
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;
  const calendarRef = useRef<FullCalendar | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<string>("dayGridMonth");
  const [day, setDay] = useState<Date | null>(new Date());
  const [open, setOpen] = useState(false);
  const [schedules, setSchedules] = useState<TSchedule[]>([]); // all schedules
  const [events, setEvents] = useState<TEvent[]>([]); // schedules to event
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedHospCode, setSelectedHospCode] = useState<string>("");
  const [selectedDoctor, setSelectedDoctor] = useState<IDoctor | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<TClinic>();
  const [selectedEvent, setSelectedEvent] = useState<TSchedule | null>(null);
  const [referral, setReferral] = useState<IReferral | null>(null);
  const [openAppointment, setOpenAppointment] = useState<boolean>(false);

  const onFetchReferral = async (referId: string) => {
    try {
      const result = await fetchReferral({ token: idToken, referId });
      setReferral(result);
      handleSelectedHospCode(result.data.toHospCode);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenAppointment = (status: boolean) => setOpenAppointment(status);

  const handleSelectedClinic = (clinic: TClinic) => setSelectedClinic(clinic);

  const handleOpenModal = (status: boolean) => {
    setOpen(status);
  };

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView: string) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();

      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectEvent = (arg: EventClickArg) => {
    const isVerify = regexVerifyApppointment.test(window.location.pathname);
    const isDoctor = regexDoctorAppointment.test(window.location.pathname);
    if (!isVerify && !referId && !isDoctor) return;
    setDay(arg.event.start);
    if (isVerify || isDoctor) setOpenAppointment(true);
    if (referId) handleOpenModal(true);

    const schedule = schedules.find(
      (schedule) => schedule._id === arg.event.id
    );
    if (schedule) {
      setSelectedEvent(schedule);
    }
  };

  const handleSelectedDoctor = (doctor: IDoctor) => setSelectedDoctor(doctor);

  const handleSelectedHospCode = (hospCode: string) =>
    setSelectedHospCode(hospCode);

  const handleEventView = (schedules: TSchedule[], view: string) => {
    if (view !== "listWeek") {
      const events = schedules?.map((schedule) => ({
        title: `${
          schedule.dayOff ? "แพทย์ลาหยุด" : `ว่าง ${schedule.remaining} ที่`
        } `,
        id: schedule._id,
        start: new Date(schedule.start),
        end: new Date(schedule.end),
      }));
      setEvents(events);
      return;
    }
    const referEvents: TEvent[] = [];
    for (let index = 0; index < schedules.length; index++) {
      const schedule = schedules[index];
      if (schedule.refersAppointment.length > 0) {
        for (const refer of schedule.refersAppointment) {
          referEvents.push({
            title: `${refer.data.ptname}`,
            id: schedule._id,
            start: new Date(schedule.start),
            end: new Date(schedule.end),
          });
        }
      }
    }
    setEvents(referEvents);
  };

  const handleFetchSchedules = async (
    startDate: Date | null,
    endDate: Date | null,
    hospCode: string,
    doctorId?: string
  ) => {
    try {
      if (
        !doctorId ||
        !startDate ||
        !endDate ||
        !hospCode ||
        !selectedClinic?._id
      ) {
        return;
      }

      const data = await fetchSchedules(
        idToken,
        doctorId,
        hospCode,
        selectedClinic._id,
        startDate,
        endDate,
        "MAKE_APPOINTMENT"
      );
      setSchedules(data);
      handleEventView(data, view);
    } catch (error) {
      console.log("fetch schedules error", error);
    }
  };

  const handleDateSet = async (sDate: Date, eDate: Date) => {
    // set for change doctor and search again
    setStartDate(sDate);
    setEndDate(eDate);
    const calMonth = new Date(sDate).getMonth() - new Date().getMonth();
    // don't flashback
    if (calMonth < -1) return; // setStartDate(new Date());

    await handleFetchSchedules(
      sDate,
      eDate,
      selectedHospCode,
      selectedDoctor?._id
    );
  };

  const onSearch = async () => {
    const shouldHospCode = selectedHospCode ? selectedHospCode : hospCode;
    await handleFetchSchedules(
      startDate,
      endDate,
      shouldHospCode,
      selectedDoctor?._id
    );
  };

  const onSubmitAppointment = async (
    scheduleId: string,
    referId: string,
    cid: string,
    clinicId: string
  ) => {
    try {
      const schedule = schedules.find(
        (schedule) => schedule._id === scheduleId
      );
      if (!schedule) return;
      if (
        schedule.refersAppointment.length === Number(schedule.totalAppointment)
      ) {
        Swal.fire("ทำนัดหมาย", "ตารางวันนี้เต็มแล้ว", "error");
        return;
      }
      if (selectedHospCode === hospCode) {
        Swal.fire("ทำนัดหมาย", "ไม่สามารถทำนัดของ รพ.ตนเองได้", "error");
        return;
      }
      if (schedule.dayOff) {
        Swal.fire("ทำนัดหมาย", "ไม่สามารถนัดเนื่องจากแพทย์ลา", "error");
        return;
      }
      await updateAppointment(idToken, scheduleId, referId, cid, clinicId);
      await handleFetchSchedules(
        startDate,
        endDate,
        selectedHospCode,
        selectedDoctor?._id
      );
      handleOpenModal(false);
      Swal.fire("ทำนัดหมาย", "", "success");
    } catch (error: any) {
      console.log(error.message);
      Swal.fire("ทำนัดหมาย", error.message, "error");
    }
  };

  useEffect(() => {
    if (referId) {
      onFetchReferral(referId);
    }
  }, [referId]);

  return (
    <div className="grid mt-20 mx-9 mb-6">
      <StyledCalendar>
        {referId && referral && (
          <>
            <ReferDetail referral={referral} />
            <CalendarToolbar
              onChangeView={handleChangeView}
              onToday={handleClickToday}
              date={date}
              view={view}
              onNextDate={handleClickDateNext}
              onPrevDate={handleClickDatePrev}
              hiddenChangeView={true}
              selectedHospCode={referral.data.toHospCode || selectedHospCode}
              onSearch={onSearch}
            >
              <MyHospFilter
                token={idToken}
                hospCode={referral.data.toHospCode || selectedHospCode}
                onSelectedDoctor={handleSelectedDoctor}
                onSelectedClinic={handleSelectedClinic}
              />
            </CalendarToolbar>
          </>
        )}

        <Card
          className="px-6 py-6 overflow-scroll"
          sx={{ borderRadius: "20px" }}
        >
          <FullCalendar
            ref={calendarRef}
            weekends
            editable
            droppable
            selectable
            rerenderDelay={10}
            allDayMaintainDuration
            eventResizableFromStart
            initialDate={date}
            initialView={view}
            dayMaxEventRows={10}
            events={events}
            eventContent={(e) =>
              view !== "listWeek" ? (
                <p
                  className={`text-center text-gray-50 text-lg w-full h-full rounded-lg bg-blue-400`}
                >
                  {e.event.title}
                </p>
              ) : (
                <b>{e.event.title}</b>
              )
            }
            headerToolbar={false}
            datesSet={(event) => {
              console.log(event.start, event.end);

              handleDateSet(event.start, event.end);
            }}
            eventClick={handleSelectEvent}
            height={"auto"}
            plugins={[listPlugin, dayGridPlugin, timeGridPlugin]}
          />
        </Card>
      </StyledCalendar>
      <Dialog open={open} onClose={() => handleOpenModal(false)}>
        <DialogTitle>นัดหมาย</DialogTitle>
        <DialogContent>
          {selectedEvent && referral && selectedClinic && (
            <AppointmentForm
              day={day}
              schedule={selectedEvent}
              token={idToken}
              referral={referral}
              onSubmit={onSubmitAppointment}
              clinicNote={selectedClinic.note}
            />
          )}
        </DialogContent>
      </Dialog>
      {selectedEvent && (
        <AppointmentDrawer
          open={openAppointment}
          onHandleDrawer={handleOpenAppointment}
          scheduleId={selectedEvent._id}
          token={idToken}
          isDoctor={regexDoctorAppointment.test(window.location.pathname)}
        />
      )}
    </div>
  );
}

export default Calendar;
