import React, { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { menu } from "../NavigationMain/menu";
import CardItem from "./CardItem";
import { ServicePermissionEvent } from "../../constants/servicePermission";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import file43 from "../../assets/pdf/Mapping 43 file document_13_Aug_24.pdf";
import { AnyObject } from "@casl/ability/dist/types/types";

function Home() {
  const {
    appData: { permissionRules },
  }: any = useSelector((state) => state);

  const ability = useMemo(
    () =>
      permissionRules
        ? new Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>(
            permissionRules
          )
        : undefined,
    [permissionRules]
  );

  const cardItem = useMemo(
    () =>
      menu.map(
        (card) =>
          (!card.servicePermission ||
            (ability &&
              card.servicePermission.some((service) =>
                ability.can(ServicePermissionEvent.READ, service)
              ))) && (
            <CardItem key={`${card.text}-${card.description}`} {...card} />
          )
      ),
    [menu, ability]
  );

  const handleOpenPdf = useCallback(() => {
    window.open(file43, "_blank");
  }, []);

  return (
    <div className="flex justify-center items-center mt-16 static">
      <div className="grid grid-cols-2 gap-5 my-6 md:grid-cols-3">
        {cardItem}
      </div>
      <div className="hidden fixed z-10 bottom-1 font-bold sm:block w-full">
        <Box display={"flex"} justifyContent={"space-between"} paddingX={3}>
          <div className="flex">
            <img src="/ever_logo.png" className="h-8 mr-2" alt="logo" />
            <span className="self-end">Powered By Ever</span>
          </div>
          {window.location.hostname.includes("refer-pri") && (
            <Box display={"flex"} alignItems={"flex-end"}>
              <Typography
                variant="caption"
                sx={{ cursor: "pointer" }}
                onClick={handleOpenPdf}
              >
                เอกสารเทียบเคียงระหว่าง 43 แฟ้มและฐานข้อมูล Ever
              </Typography>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
}

export default Home;
