// NOTE: If you edit something in this file, don't forget to update the constant in the frontend as well.
// (packages/schema/v10/constants/ServicePermissionService.js)
// (packages/schema/v10/constants/ServicePermissionEvent.js)

export const ServicePermissionService = {
  REFERRAL: "Referral",
  REPORT: {
    OWN_HOSPITAL_REPORT: "OwnHospitalReport",
    ANY_HOSPITAL_REPORT: "AnyHospitalReport",
    CHILD_HOSPITAL_REPORT: "ChildHospitalReport",
  },
  MANAGEMENT: {
    USER_MANAGEMENT: {
      USER_MEMBER: "UserMember",
      OWN_HOSPITAL_USER: "OwnHospitalUser",
      ANY_HOSPITAL_USER: "AnyHospitalUser",
      CHILD_HOSPITAL_USER: "ChildHospitalUser",
    },
    HOSPITAL_MANAGEMENT: "HospitalManagement",
    PERMISSION_MANAGEMENT: "PermissionManagement",
  },
  KNOWLEDGE_GRAPH: "KnowledgeGraph",
  POPULATION_HEALTH: "PopulationHealth",
  ONLINE_STATUS: "OnlineStatus",
  SAMPLE_DATA: "SampleData",
  SETTING_REASON_REFERRAL: "SettingReasonReferral",
  CHANGE_REFER_STATUS: "ChangeReferStatus",
  ANY_HOSPITAL_REFER: "AnyHospitalRefer",
  APPOINTMENT_OPD: "appointmentOPD",
};

export enum ServicePermissionEvent {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}
